// import { axios } from "axios"
import { axios } from "@/utils/request";
/**
 * 股票信息
 */

export const Stock = () => axios.get('/sys/stock_infomation/search')

/**
 * 股票信息趋势图
 */

export const Stockimg = () => axios.get('/sys/stock_infomation/list_pri')


/*
投资者关系日历
*/
export const TouZiZheRiLi = () => axios.get('/jeecg-system/frontend/investorRelationCalendr/list_all')

// 公司资料
export const ZiliList = () => axios.get('/jeecg-system/frontend/companyInformation/list_all')

// 公司推介材料
export const CaiLiaoData = (parmas) => axios.get('/jeecg-system/frontend/companyIntroduceFile/list_all', parmas)

// 财务报告
export const BaoGaodata = () => axios.get('/jeecg-system/frontend/financialReport/list_all')

// 上市文件
export const ShangShiWenJian = () => axios.get('/jeecg-system/frontend/listingDocument/list_all')

// 企业管治
export const QiYeGuanZhi = () => axios.get('/jeecg-system/frontend/companyControl/list_all')

// 公告及通函
export const GongGao = () => axios.get('/jeecg-system/frontend/announcementCircular/list_all')

// 投资者查询
export const TouZiZheChaXun=()=>axios.get('/jeecg-system/frontend/sysSetting/list_all')

// 新闻稿
export const XinWenGao=(parmas)=>axios.get('/jeecg-system/common/frontend/article/active_list',parmas)



