var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"banner",style:({backgroundImage:('url('+_vm.banner+')')}),attrs:{"id":"investor_banner1"}},[_c('span',[_vm._v(_vm._s(_vm.$t("indexnav.Investor Relations"))),_c('i',{style:({
          display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
        })},[_vm._v("Investor Relations")])])]),_c('section',{staticClass:"ab-item1"},[_c('bread-crumb')],1),_vm._m(0),(false)?_c('section',{staticClass:"content",attrs:{"id":"iframeContainer"}},[_c('div',[_c('div',[_vm._v(_vm._s(_vm.moment(_vm.yearList[0]).format("YYYY")))]),_c('ul',[_c('li',{on:{"click":_vm.checkyear}},[_c('span',[_vm._v(_vm._s(_vm.$t("responsibility.Select Year")))])]),_c('ul',{style:({
            height: _vm.showyear ? 47 * 1 + 'px' : '0px',
            transition: 'all,0.4s',
          })},_vm._l((_vm.yearList),function(item){return _c('li',{key:item + 'key'},[_vm._v(" "+_vm._s(_vm.moment(item).format("YYYY"))+" ")])}),0)])])]):_vm._e(),(false)?_c('section',[_c('div',{staticClass:"select-content"},[_c('ul',{staticClass:"comm-list m-list no-type"},_vm._l((_vm.articleList),function(item){return _c('li',{key:item.id,staticClass:"ver-tc"},[_c('span',[_vm._v(_vm._s(_vm.moment(item.publishDate).format("YYYY-MM-DD")))]),_c('span',[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.checkArticle(item.id, item.outUrl)}}},[_vm._v(_vm._s(item.title))])])])}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{attrs:{"id":"iframeContainer"}})])
}]

export { render, staticRenderFns }