<template>
  <!-- 新闻稿 -->
  <div>
    <!-- :style="{backgroundImage:('url('+banner+')')}" -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}" >
      <!-- <img
        src="../../assets/images/investors/investor_banner01.jpg"
        :alt="$t('indexnav.Investor Relations')"
      /> -->
      <span
        >{{ $t("indexnav.Investor Relations")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Investor Relations</i
        ></span
      >
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      {{ $t("indexnav.Press Release") }} -->
      <bread-crumb />
    </section>
    <section>
      <div id="iframeContainer"></div>
    </section>
    <!-- 选择年份 -->
    <section v-if="false" class="content" id="iframeContainer">
      <div>
        <!-- 当前年份 -->
        <div>{{ moment(yearList[0]).format("YYYY") }}</div>
        <!-- 下拉框 -->
        <ul>
          <li @click="checkyear">
            <span>{{ $t("responsibility.Select Year") }}</span>
          </li>
          <ul
            :style="{
              height: showyear ? 47 * 1 + 'px' : '0px',
              transition: 'all,0.4s',
            }"
          >
            <li v-for="item in yearList" :key="item + 'key'">
              {{ moment(item).format("YYYY") }}
            </li>
          </ul>
        </ul>
      </div>
    </section>
    <!-- 内容 -->
    <section v-if="false">
      <div class="select-content">
        <ul class="comm-list m-list no-type">
          <li class="ver-tc" v-for="item in articleList" :key="item.id">
            <span>{{ moment(item.publishDate).format("YYYY-MM-DD") }}</span>
            <span
              ><a @click="checkArticle(item.id, item.outUrl)" target="_blank">{{
                item.title
              }}</a></span
            >
          </li>
          <!-- <li class="ver-tc">
            <span>2020-04-27</span>
            <span
              ><a
                href="https://cj.sina.com.cn/articles/view/5115326071/130e5ae77020010mr3?from=finance"
                target="_blank"
                >锦欣生殖（1951.HK）：业绩依然亮眼，人才战略、管理输出战略逐渐浮出水面</a
              ></span
            >
          </li> -->
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { PageType } from "@/utils/constant";
import { getAlltype, getArticle } from "@/api/news";
import { XinWenGao } from "@/api/investors";
import { getAction } from "@/api/manage";
import moment from "dayjs";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [ifarmeMixin],
  data() {
    return {
      banner:'',
      showyear: false,
      resList: [],
      dataList: [],
      nowYear: "2021",
      yearList: [],
      articleList: [], 
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_press.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_press.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_press.php",
    };
  },
  methods: {
    moment,
    checkyear() {
      this.showyear = !this.showyear;
    },
    // 点击文章
    checkArticle(id, url) {
      if (url) {
        window.location.href = url;
      } else {
        this.$router.push("/newsinfo?id=" + id);
      }
    },
    // getArticleList(lang) {
    //   getAlltype().then((res) => {
    //     console.log(res);
    //     for (let i of res.result) {
    //       if (i.haveImg == "N") {
    //         console.log(i);
    //         getArticle({ typeId: i.id, languageType: lang }).then((item) => {
    //           this.articleList = item.result.records;
    //           console.log(this.articleList);
    //         });
    //       }
    //     }
    //   });
    // },
    getArticleList(lang) {
      getArticle({ typeId: 2, languageType: lang }).then((item) => {
        this.articleList = item.result.records;
        let list = [];
        for (let i of item.result.records) {
          list.push(moment(i.publishDate).format("YYYY"));
        }
        // 数组去重
        this.yearList = Array.from(new Set(list));
      });
    },
  },
  created() {
    //   // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.XINWENGAO,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });
    // console.log(this.$store.state.nowlanguage);
    if (this.$store.state.nowlanguage == "zh") {
      this.getArticleList("sample");
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.getArticleList("tranditional");
    }
    if (this.$store.state.nowlanguage == "en") {
      this.getArticleList("EN");
    }
    // this.getArticleList(this.$store.state.nowlanguage);
    // let typeId=2
    // XinWenGao().then(res=>{
    //   console.log(res);
    // })
    // let obj = { typeId: 1, languageType: "EN" };
    // getAction("/jeecg-system/common/frontend/article/active_list", obj).then(
    //   (res) => {}
    // );
  },
  computed: {
    getVuexData() {
      return this.$store.state.nowlanguage;
    },
  },
  watch: {
    getVuexData(newvalue, oldvalue) {
      if (newvalue == "zh") {
        this.getArticleList("sample");
      }
      if (newvalue == "tr") {
        this.getArticleList("tranditional");
      }
      if (newvalue == "en") {
        this.getArticleList("EN");
      }
    },
  },
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped lang=less>
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article div:nth-child(1) {
  display: flex;
  align-items: flex-start;
  position: relative;
}

article div:nth-child(1) div {
  background: #165da3;
  padding: 0.3em 0.9em;
}

article div:nth-child(1) div ul {
  display: none;
  z-index: 99;
}

article div:nth-child(1) div ul li:last-child {
  padding-bottom: 0.6em;
}

article div:nth-child(1) div ul li a {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 1.6em;
  padding: 0.3em 0;
}

article div:nth-child(1) div div a {
  color: #fff;
  font-size: 1em;
  display: block;
}

article div:nth-child(1) div:hover ul {
  display: block;
  position: absolute;
  margin: 2em 0 0 0;
  background: #165da3;
  width: 100%;
  left: 0;
}

article div:nth-child(2) > div dl {
  margin: 0 0 20px 0;
}

article div:nth-child(2) > div dl dt {
  height: 30px;
}

article div:nth-child(2) > div dl dd {
  border-bottom: 1px solid #dddddd;
  overflow: hidden;
  padding: 20px 0;
}

article div:nth-child(2) > div dl dd a {
  line-height: 17px;
  color: #8f8f8f;
  float: left;
}

article div:nth-child(2) > div dl dd span {
  color: #666666;
  padding: 0 0 5px 0;
  display: block;
}

article div:nth-child(2) > div dl dd span strong {
  color: #18325f;
  float: left;
  width: 60px;
}

/*#ver-en .calendar_list dl dd span strong {
    width: 100px;
}*/

#iframeContainer {
  margin: 3em auto 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
}

iframe {
  margin: 0.6em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article {
    padding: 0 1.5em 1.5em 1.5em;
  }
}

/* 选择年份 */
.content {
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      top: 1px;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 58px 10px 15px;
        position: relative;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
/* 三角 */
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.content div:first-child > div {
  color: #18325f;
  font-size: 1.3rem !important;
  font: 14px/2 "宋体", Arial;
}
.select-content {
  margin: 0 30px;
  min-height: 360px;
  ul.comm-list li {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }
  ul.comm-list li span:first-child {
    width: 150px;
    float: left;
  }
  ul.comm-list li span:last-child {
    display: table-cell;
  }
  a {
    color: #666666;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  a:hover {
    color: #e60044;
  }
}
</style>
