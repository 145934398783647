import Vue from 'vue'
export const ifarmeMixin={
    methods: {
        setIframe(url){
            new easyXDM.Socket({ 
              remote:url,
              swf:'easyxdm.swf',
              container:'iframeContainer',
              hash:true,
              onMessage:function(message,origin){
                console.log(message,origin);
                this.container.getElementsByTagName('iframe')[0].style.height = message + 'px'
                this.container.getElementsByTagName('iframe')[0].scrolling='no'
                this.container.getElementsByTagName('iframe')[0].style.width="100%"
              }
            })
          }
    }
}
